import { AboutUs } from "./AboutUs"
import { GlobalStyles } from "components/GlobalStyles"
import { Header } from "components/Header"
import React from "react"
import styled from "styled-components/macro"

const MapContainer = styled.div`
  flex: 1;
  background-color: gray;
  z-index: 0;
`

export const App: React.FC = () => {
  const [isAboutUsShowing, showAboutUs] = React.useState(false)
  const hideAboutUs = React.useCallback(() => showAboutUs(false), [])
  const toggleAboutUs = React.useCallback(() => showAboutUs(!isAboutUsShowing), [isAboutUsShowing])

  const stopPropagation = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }, [])

  React.useEffect(() => {
    if (isAboutUsShowing) {
      document.addEventListener("click", toggleAboutUs, false)
    }

    return () => document.removeEventListener("click", toggleAboutUs, false)
  }, [isAboutUsShowing, hideAboutUs, toggleAboutUs])

  return (
    <>
      <GlobalStyles />
      <Header isAboutUsShowing={isAboutUsShowing} toggleAboutUs={toggleAboutUs} />
      <AboutUs isShowing={isAboutUsShowing} onClick={stopPropagation} />
      <MapContainer>
        <iframe
          title="Vamos Gordo Travel Map"
          src="https://vamosgordo.travelmap.net"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
        />
      </MapContainer>
    </>
  )
}
