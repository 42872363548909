import { createGlobalStyle } from "styled-components"
import { fonts } from "fonts/config"

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box; 
  }

  html,
  body,
  main {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    overscroll-behavior-y: none;
  }
  
  body {
    font-family: ${fonts.family.avenir};
    font-weight: ${fonts.weight.normal};
    font-style: ${fonts.style.normal};
  }
  
  main {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  input {
    border-radius: 0;
  }

  input:focus {
    outline: 0;
  }

  p {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  ul {
    margin: 0;
    padding: 0;
  }

  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: normal;
    font-size: unset;
  }
  
  :root {
    --map-width: calc(100% - 370px);
  }

  @media only screen and (max-device-width: 840px), only screen and (max-width: 840px) {
    :root {
      --map-width: 100%;
    } 
  }
`
