import { colors } from "colors"
import { fonts } from "../fonts/config"
import React from "react"
import aboutUs from "images/about-us.jpg"
import styled, { css } from "styled-components/macro"

const AboutUsStyled = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: var(--map-width);
  padding: 30px;
  background-color: ${colors.orange};
  z-index: 1;
  transform: translateY(-100%);
  transition: transform 500ms ease-in-out, box-shadow 500ms ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      transform: translateY(0);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
    `}
`

const Us = styled.img`
  height: 250px;
  border-radius: 50%;
  margin-right: 30px;
`

const Details = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media only screen and (max-device-width: 1000px), only screen and (max-width: 1000px) {
    flex: auto;
    margin-top: 20px;
  }
`

const Title = styled.h3`
  font-weight: ${fonts.weight.medium};
  font-size: 32px;
  color: ${colors.white};
`

const Blurb = styled.div`
  font-size: 16px;
  color: ${colors.tan};

  p {
    margin-bottom: 10px;
  }
`

export const AboutUs: React.FC<{ isShowing: boolean; onClick: (event: React.MouseEvent) => void }> =
  ({ isShowing, onClick }) => (
    <AboutUsStyled isActive={isShowing} onClick={onClick}>
      <Us src={aboutUs} />
      <Details>
        <Title>A little about us</Title>
        <Blurb>
          <p>
            Hi friends and family! As many of you already know, we are moving back to the east
            coast! For the past 8 years, California has been the place we call home, providing us
            with endless outdoor activities and lifelong friendships. Moving back east has always
            been a part of our future and it is bittersweet that our time has come.
          </p>
          <p>
            Over the next few months, the three of us will be living out of our van, Gordo. We will
            be visiting our favorite spots on the west coast and exploring some bucket list areas on
            the trek back. Dewey is stoked to be with us every step of the way and not-so-patiently
            awaits our stops near water! If you have any recommendations for spots to check out
            along our route, please let us know. Thank you for joining us on our journey!
          </p>
        </Blurb>
      </Details>
    </AboutUsStyled>
  )
