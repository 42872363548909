export const fonts = {
  family: {
    avenir: "'Avenir', Helvetica, Arial, sans-serif",
  },
  weight: {
    ultralight: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 800,
    heavy: 900,
  },
  style: {
    normal: "normal",
    oblique: "oblique",
  },
}
